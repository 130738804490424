import {Component, Inject, OnInit} from '@angular/core';
import {WINDOW} from '../../../../providers/window.provider';
import {UtilService} from '../../../../services/util.service';

export const COOKIES_BANNER = 'cookies_banner';

@Component({
    selector: 'shared-cookies-banner',
    templateUrl: './cookies-banner.component.html',
    styleUrls: ['./cookies-banner.component.scss'],
})
export class CookiesBannerComponent implements OnInit {

    isVisible = true;

    constructor(
        @Inject(WINDOW) private window: Window,
                private utilService: UtilService,
        ) {
    }

    ngOnInit() {
        const self = this;
        /**
         * The banner should be visible just on.co.uk
         */
        const host = this.window.location.host;
        if ( host.includes('.co.uk') || host.includes('.eu')) {
            this.isVisible = (!self.utilService.localStorageGetItem(COOKIES_BANNER));
        } else {
            this.isVisible = false;
        }

    }

    gotIt() {
        const self = this;
        self.utilService.localStorageSetItem(COOKIES_BANNER, true.toString());
        this.isVisible = false;
    }
}
